<template>
    <div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>同步按钮</span>
            </div>

            <el-row>
                <sendSyncMessageButton v-for="(item, index) in buttonInfos" :buttonInfo='item'></sendSyncMessageButton>
            </el-row>

        </el-card>



<!--        <el-card class="box-card" v-if="this.showHideUserIds.includes(this.userId)">-->
<!--            <div slot="header" class="clearfix">-->
<!--                <span>隐藏同步按钮</span>-->
<!--            </div>-->

<!--            <el-row>-->
<!--                <sendSyncMessageButton v-for="(item, index) in hideButtonInfos" :buttonInfo='item'></sendSyncMessageButton>-->
<!--            </el-row>-->

<!--        </el-card>-->

    </div>
</template>
<script>

import sendSyncMessageButton from '@/views/sync/sendSyncMessageButton.vue';
import Config from '@/utils/config';
import Cache from '@/utils/cache';

export default {
    components: { sendSyncMessageButton },
    name: 'fullsync',
    data() {
        return {
            fulltag: '',
            buttonInfos: [
                {buttonName: '删除产品文件同步', label: 'java.arc.center.subject.goods.file.relation.all', lockTime: 15},
                {buttonName: '删除产品图片同步', label: 'java.arc.center.subject.goods.image.relation.all', lockTime: 30},
                {buttonName: '删除产品参数同步', label: 'java.arc.center.subject.goods.parameter.relation.all', lockTime: 30},
                {buttonName: '删除类目同步', label: 'java.arc.center.subject.goods.classify.del.all', lockTime: 30},
                {buttonName: '删除类目与产品关系同步', label: 'java.arc.center.subject.goods.classify.relation.del.all', lockTime: 30},
                {buttonName: '删除类目关联参数同步', label: 'java.arc.center.subject.goods.classify.parameter.del.all', lockTime: 30},
            ],
            hideButtonInfos: [

            ],
            userId: Cache.get(Config.userInfo).org_user_id,
            //工号
            showHideUserIds: [

                //测试环境
                "102957",//张维阳

                //正式环境
                "102665",//张维阳
                "102666",//刘冰
                "102493",//陈伟
                "102321",//李钊鑫
                "101353" //陈军
            ],

        };
    },
    watch: {},
    created() {

    },
    mounted() {
    },
    methods: {
    }
};


</script>


<style scoped>


/*.box-card {*/
/*    width: 800px;*/
/*}*/

</style>